<template>
    <b-card class="text-center">
     
      <b-avatar
        class="mb-1"
        :variant="`light-${color}`"
        size="45"
      >
      <b-link  :to="url">
        <feather-icon
          size="21"
          :icon="icon"
         
        />
    </b-link>
      </b-avatar>
      <div class="truncate">
        <b-link   :to="url">
            <h2 class="mb-25 font-weight-bolder">
                {{ statisticTitle }}
            </h2>
        </b-link>
     
      </div>
    </b-card>
  </template>
  
  <script>
  import { BCard, BAvatar,BLink } from 'bootstrap-vue'
  
  export default {
    components: {
      BCard,
      BAvatar,
      BLink
    },
    props: {
      icon: {
        type: String,
        required: true,
      },
      url: {
        type: [Number, String],
        required: true,
      },
      statisticTitle: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: 'primary',
      },
    },
  }
  </script>
  