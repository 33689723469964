<template>
    <div>
      
       
        <b-row>
            <b-col cols="12" lg="4">
                <coparate-congratulation :data="userData" />
            </b-col>
            <b-col cols="12" lg="8">
                <ecommerce-statistics v-if="Items.length > 0" :data="Items" />
            </b-col>
            <b-col cols="12" lg="12">
                <queue-list />
            </b-col>
          
            
        </b-row>
    </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import StatisticCardLink from '@core/components/statistics-cards/StatisticCardLink.vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import CoparateCongratulation from './CoparateCongratulation.vue'
import EcommerceStatistics from './EcommerceStatistics.vue'
import { setgroups } from 'process'
import { ref,onUnmounted } from '@vue/composition-api'
import Ecommerce from '../ecommerce/Ecommerce.vue'
import store from '@/store'
import reportStoreModule from '@/views/report/reportStoreModule'
import QueueList from '@/views/queue/queue-list/QueueList.vue'
export default {
    components:{
        BRow, BCol,
        CoparateCongratulation,
        StatisticCardLink,
        EcommerceStatistics,
        Ecommerce,
        QueueList

    },
    setup(){
        const REPORT_STORE_MODULE_NAME = 'app-report';
        if (!store.hasModule(REPORT_STORE_MODULE_NAME)) store.registerModule(REPORT_STORE_MODULE_NAME, reportStoreModule)
        onUnmounted(() => {
            if (store.hasModule(REPORT_STORE_MODULE_NAME)) store.unregisterModule(REPORT_STORE_MODULE_NAME)
        });
        const userData = JSON.parse(localStorage.getItem('userData'));
        const Items = ref([]);
        console.log('userData',userData);
        const GroupLink = ref([]);
        const fetchData = async ()=>{
        
        
            let response =  await store.dispatch('app-report/fetchDashBoardData', {
                    // id:queue_id,
                        branch_id:userData.branch_id
                    
                    
                    
                
                    });
            if(response.data.success){
                Items.value = response.data.data.statics;
            
    
            }
            
            
    
        }
    fetchData();
        //console.log('userData',userData);

        return {
            userData,
            GroupLink,
            Items

        }

    }
    
}
</script>